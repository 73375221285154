var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-section" }, [
      _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
        _vm._v(
          " " +
            _vm._s(_vm.WORDS.LINE_ID) +
            "との" +
            _vm._s(_vm.WORDS.LINK) +
            "が完了しました "
        ),
      ]),
      _c("p", { staticClass: "p-contact__thanks" }, [
        _vm._v(
          " アプリと" +
            _vm._s(_vm.WORDS.LINE_ID) +
            "の" +
            _vm._s(_vm.WORDS.LINK) +
            "が完了しました。" +
            _vm._s(_vm.WORDS.LINE) +
            "を使ってアプリをお得に利用しましょう。 "
        ),
      ]),
      _c(
        "div",
        { staticClass: "p-link-line__actions" },
        [
          _c(
            "Button",
            {
              staticClass: "p-linkOnlineStore__action",
              attrs: {
                href: _vm.$customUrlScheme.page("root/home/1"),
                "style-type": "tertiary",
                fluid: "",
                flat: "",
              },
            },
            [_vm._v(" " + _vm._s(_vm.WORDS.BACK_TOP) + " ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }