<template>
  <div>
    <div class="c-section">
      <h1 class="c-title2 c-title2--mb16">
        {{ WORDS.LINE_ID }}との{{ WORDS.LINK }}が完了しました
      </h1>
      <p class="p-contact__thanks">
        アプリと{{ WORDS.LINE_ID }}の{{ WORDS.LINK }}が完了しました。{{
          WORDS.LINE
        }}を使ってアプリをお得に利用しましょう。
      </p>

      <div class="p-link-line__actions">
        <Button
          :href="$customUrlScheme.page('root/home/1')"
          style-type="tertiary"
          fluid
          flat
          class="p-linkOnlineStore__action">
          {{ WORDS.BACK_TOP }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup: () => {
    return {
      WORDS
    };
  }
});
</script>
